import { useParams } from "react-router-dom"
import { PermissionedUserProps } from "../admin/sharedInterface"
import { CreateNewUser } from "../admin/createNewUser"
import { CreateNewAppointment } from "../admin/createNewAppointment"
import { ScheduleAppointmentsView } from "../appointments/schedule"
import Title from "antd/es/typography/Title"
import { Coach } from "../models"
import { useEffect, useState } from "react"
import { DataStore } from "aws-amplify"
import _ from "lodash"
import { Button, Collapse, Divider } from "antd"
import { CoachViewLink } from "../admin/coachViewLink"
import { downloadFile } from "../common/functions"
import { ExportOutlined } from "@ant-design/icons"

export interface IdentifiedUserProps extends PermissionedUserProps {
  userId: string
}



export const AdminView = ({ groups, userId }: IdentifiedUserProps): JSX.Element => {
  const [loading, setLoading] = useState({ activities: false, mood: false });

  
  

  const handleDownload = async (name: string, filename: string) => {
    const path = `/${name}`;
    setLoading(prevState => ({ ...prevState, [name]: true }));

    try {
        await downloadFile(path, filename);
    } finally {
      setLoading(prevState => ({ ...prevState, [name]: false }));
    }
};


  return (
    <>
      <Title level={1}>Welcome Admin</Title>
      <Collapse>
        <Collapse.Panel header=<strong>Tools</strong> key="1">
          <CreateNewUser />
          <CreateNewAppointment  />
          <CoachViewLink userId={userId} groups={groups} />
          <p>
            <ExportOutlined /> Exports:
            <Button loading={loading.activities} onClick={() => {handleDownload("activities", "activities.csv")}}>Activities</Button>
            <Button loading={loading.mood} onClick={() => {handleDownload("mood", "mood.csv")}}>Mood</Button>
          </p>

        </Collapse.Panel>
      </Collapse>

      
      <Divider style={{ paddingTop: "8px" }} />
      <ScheduleAppointmentsView participantId={undefined} coachId={undefined} groups={groups} />
    </>
  )
}



export const CoachView = ({ groups, userId }: IdentifiedUserProps): JSX.Element => {
  let { coachId } = useParams();
  const [coach, setCoach] = useState<Coach|undefined>(undefined)

  const resolvedUserId = coachId ?? userId

  useEffect(() => {
    DataStore.query(Coach, (coach) => coach.coachId.eq(resolvedUserId))
    .then((coaches) => {
      const coach = _.first(coaches)
      setCoach(coach)
    })

  }, [resolvedUserId])


return (<>
    <Title level={1}>Welcome Coach {coach?.nickname ?? ""}</Title>
    <Title level={3}>Tools</Title>
    <CreateNewAppointment coachId={resolvedUserId}  />
    <Divider />
    <ScheduleAppointmentsView participantId={undefined} coachId={resolvedUserId} groups={groups} title="Real Time Coaching Sessions" />
  </>)
}

export const HomeView = ({ groups, userId }: IdentifiedUserProps): JSX.Element => {
  return <CoachView groups={groups} userId={userId} />
}


