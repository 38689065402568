import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    message
} from 'antd'
import dayjs from 'dayjs';
import { UserAddOutlined } from '@ant-design/icons'
import { Text } from '@aws-amplify/ui-react';
import { DataStore } from "aws-amplify";
import { Coach } from "../models"
import _ from 'lodash';
import { UserHelper } from './UserHelper';
import { createCoachedParticipant } from './coachedParticipant';
import { getTimezonesForCountry } from 'countries-and-timezones'
import * as EmailValidator from 'email-validator';
import { CohortHelper } from './CohortHelper';

export interface CreateNewUserProps  {
    coachId?: string|undefined
}
    
export function CreateNewUser({coachId = undefined}: CreateNewUserProps): JSX.Element  {
    const [createUserModalVisible, setCreateUserModalVisible] = useState(false)
    const [coaches, setCoaches] = useState<Coach[]>([])
    const [nickName, setNickName] = useState<string | undefined>(undefined)
    const [email, _setEmail] = useState<string | undefined>(undefined)
    const setEmail = (email: string) => _setEmail(email?.trim() ?? undefined)
    const defaultDate = dayjs().startOf("week").add(1, "week").toDate()
    const [startDate, setStartDate] = useState<Date>(defaultDate)
    const [initialCoachId, setInitialCoachId] = useState<string | undefined>(undefined)
    const [assignedCoachId, setAssignedCoachId] = useState<string | undefined>(undefined)
    const [zoneinfo, setZoneinfo] = useState<string | undefined>(undefined)
    const [currentlyCreatingUser, setCurrentlyCreatingUser] = useState<boolean>(false)

    const handleOk = () => {
        setCurrentlyCreatingUser(true)
        createUser(email, assignedCoachId, nickName, startDate, zoneinfo).then((didCreateUser) => {
            setCurrentlyCreatingUser(false)
            setCreateUserModalVisible(!didCreateUser);
        })
        .catch(() => {
            setCurrentlyCreatingUser(false)
        })
    };

    const handleCancel = () => {
        setCreateUserModalVisible(false);
    };

    const showCreateNewUserModal = () => {
        setCreateUserModalVisible(true)
    }

    const setDefaultZoneInfo = () => {
        setZoneinfo(dayjs.tz.guess())
    }

    const createUser = async (email?: string, assignedCoachId?: string, nickName?: string, startDate?: Date, zoneinfo?: string): Promise<boolean> =>{
        
        if (email && startDate && zoneinfo) {
            try {
        
                return new UserHelper().createUser(email, startDate, zoneinfo)
                .then((cognitoUser) => {
                    return createCoachedParticipant({coachId: assignedCoachId, participantId: cognitoUser.userName, participantNickname: nickName})
                        .then(() => {
                            return new CohortHelper().createCohortIfNecessary(cognitoUser.attributes.cohort_start_date)
                        })                        
                }).then(() => {
                        setNickName("")
                        setEmail("") 
                        setStartDate(defaultDate)
                        setAssignedCoachId("")
                        setDefaultZoneInfo()            

                        return Promise.resolve(true)
            })

            } catch (error) {
                message.error("Error, Could not create user. Try again.")
                console.error("Could not save CoachedParticipant", error)
                return Promise.resolve(false)
            }
        } else {
            return Promise.resolve(false)
        }
       
    }

    useEffect(() => {
        DataStore.observeQuery(Coach)
            .subscribe((snapshot) => {
                const { items } = snapshot
                setCoaches(_.sortBy(items, "nickname"))
            })
        setDefaultZoneInfo()            
    }, [])

    useEffect(() => {
        setAssignedCoachId(coachId)
        setInitialCoachId(coachId)
    }, [coachId])

    const onSelectDate = (date: dayjs.Dayjs | null, dateString: string) => {
        setStartDate(date?.toDate() ?? defaultDate)
    };

    const americanTimezones = getTimezonesForCountry('US')

  return <>
      <p onClick={showCreateNewUserModal}>
          <UserAddOutlined  /> Create a new user
      </p>

      <Modal 
        title="Create A New User" 
        open={createUserModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={[
            <Button key="back" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button key="submit" type="primary" disabled={!Boolean(EmailValidator.validate(email ?? "") && startDate && zoneinfo && nickName && assignedCoachId)} onClick={handleOk} loading={currentlyCreatingUser}>
                Create New User
            </Button>
        ]}
    >
          <Form
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              layout="horizontal"
              style={{ maxWidth: 600 }}
          >
              <Form.Item label="Nickname" rules={[{ required: true }]}>
                  <Input 
                      onChange={(e) => setNickName(e.target.value)}
                      value={nickName}
                  />
              </Form.Item>
              <Form.Item label="Email" rules={[{ required: true }]}>
                  <Input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                  />
              </Form.Item>
              <Form.Item label="Start Date" rules={[{ required: true }]}>
                  <DatePicker 
                      onChange={onSelectDate}
                      value={dayjs(startDate)}
                  />
              </Form.Item>
              <Form.Item label="Time Zone" rules={[{ required: true}]}>
                  <Select onChange={(value: string) => setZoneinfo(value)} value={zoneinfo}>
                      {americanTimezones.map((timezone) => {
                          return <Select.Option value={timezone.name} key={`option_${timezone.name}`} >{timezone.name}</Select.Option>
                      })}
                  </Select>
              </Form.Item>
              <Form.Item label="Assign a Coach" rules={[{ required: true }]}>
              {
                _.isNil(initialCoachId) ? 
                    (
                        <Select onChange={(value: string) => setAssignedCoachId(value)} value={assignedCoachId}> 
                        {coaches.map((coach) => {
                          return <Select.Option value={coach.coachId}  key={`option_${coach.coachId}`} >{coach.nickname}</Select.Option> })}
                        </Select>
    
                    )
                 : (
                    <>
                    <Text>
                        {coaches.find((coach) => coach.coachId === initialCoachId)?.nickname}
                    </Text>
                    </>
                 )

              }

              </Form.Item>
          </Form>
      </Modal>
  </>
};

