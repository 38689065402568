import { API, Auth } from "aws-amplify";



export const downloadFile = async (path: string, filename: string) => {
    try {
      const apiName = 'export';
      const apiInit = { 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        } 
      };
  
      const response = await API.get(apiName, path, apiInit);
      const csvContent = response.body;
  
      // Create a blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
  
      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };